html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

button {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

button:focus {
    outline: none !important;
}